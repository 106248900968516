import { IsoDate } from "@geotoura/shared/time/isoDate";

export type Gregorian	= Readonly<{
	// 1..31
	day:	number,
	// 1..12
	month:	number,
	// 100..
	year:	number,
}>;

export namespace Gregorian {
	export const fromIsoDate	= (iso:IsoDate):Gregorian	=> {
		const parts	= iso.split(/[^\d]+/);
		if (parts.length !== 3)	throw new Error(`unexpected iso date ${iso}`);
		const [ year, month, day ] = parts.map(it => parseInt(it));
		return { day, month, year };
	};

	// yyyy-mm-dd
	export const toIsoDate	= (it:Gregorian):IsoDate	=>
		IsoDate.brand(
			[
				it.year	.toString().padStart(4, "0"),
				it.month.toString().padStart(2, "0"),
				it.day	.toString().padStart(2, "0"),
			]
			.join("-")
		);

	export const fromDateUtc	= (it:Date):Gregorian	=> ({
		day:	it.getUTCDate(),
		month:	it.getUTCMonth()+1,
		year:	it.getUTCFullYear(),
	});

	export const fromDateLocal	= (it:Date):Gregorian	=> ({
		day:	it.getDate(),
		month:	it.getMonth()+1,
		year:	it.getFullYear(),
	});

	//-----------------------------------------------------------------------------

	/**
	parses a european-like format:
	any non-digits are treated as a separator,
	groups of digits are expected to be in day/month/year order
	*/
	export const parseEuropean	= (s:string):Gregorian|null	=> {
		const parts	= s.split(/[^\d]+/);
		if (parts.length !== 3)				return null;

		const [ day, month, year ] = parts.map(it => parseInt(it));
		// not necessary, the check below guarantees this
		// if (day		< 1 || day		> 31)	return null;
		// if (month	< 1 || month	> 12)	return null;
		// if (year < 100)						return null;

		// NOTE this maps 0..99 to 1900..1999, so years in the first century are considered invalid
		// NOTE Date uses months in 0..11 whereas here we use months in 1..12
		const candiate = new Date(year, month-1, day);
		if (candiate.getDate()		!== day)		return null;
		if (candiate.getMonth()		!== month-1)	return null;
		if (candiate.getFullYear()	!== year)		return null;

		return { day, month, year };
	};

	// dd.mm.yyyy
	export const isoString	= (it:Gregorian):string	=>
		toIsoDate(it);

	// d.m.yyyy
	export const europeString	= (it:Gregorian):string	=>
		[
			it.day	.toString(),
			it.month.toString(),
			it.year	.toString().padStart(4, "0"),
		]
		.join(".");

	// m/d/yyyy
	export const usString	= (it:Gregorian):string	=>
		[
			it.month.toString(),
			it.day	.toString(),
			it.year	.toString().padStart(4, "0"),
		]
		.join("/");

	// d/m/yyyy
	export const ukString	= (it:Gregorian):string	=>
		[
			it.day	.toString(),
			it.month.toString(),
			it.year	.toString().padStart(4, "0"),
		]
		.join("/");
}
